<template>
  <div class="programItem" v-if="data && data.id">
    <img v-lazy="data.w_image" @click="childrenshow"/>
    <div class="label">
      <div v-if="data.is_vip == '1'" class="label-li vip">
        <span>VIP</span><i></i>
      </div>
      <!-- <br />
      <div class="label-li">
        <span>{{ data.name }}</span
        ><i style="background-color: #1C38FF;"></i>
      </div> -->
    </div>
    <div class="play-btn" @click="onPlay">
      <div
        class="play-btn-icon"
        :class="playerId == data.id && $player.isPlayIng() ? 'ing' : ''"
      ></div>
    </div>
  </div>
</template>

<style lang="scss">
.programItem {
  position: relative;
  margin-bottom: 0.533333rem;
  width: 8.56rem;
  height: 4rem;
  box-shadow: 0px 16px 56px 0px rgba(0, 0, 0, 0.6);
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .label {
    position: absolute;
    left: -0.426666rem;
    top: 0.453333rem;
    .label-li {
      position: relative;
      display: inline-block;
      padding: 0 0.133333rem;
      margin-bottom: 0.186666rem;
      height: 0.56rem;
      span {
        position: relative;
        z-index: 2;
        line-height: 0.56rem;
        font-size: 0.346666rem;
        color: #ffffff;
        font-family: "ZH";
        font-weight: bold;
      }
      i {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FF6F2F;
        transform: skewX(-15deg);
        border-radius: 0.053333rem;
      }
    }
    &.vip {
      height: 0.533333rem;
      span {
        color: #151515;
        font-size: 0.453333rem;
      }
      i {
        background-color: #ff6f2f;
      }
    }
  }
  .play-btn {
    position: absolute;
    right: -0.4rem;
    bottom: 0.266666rem;
    width: 1.066666rem;
    height: 0.853333rem;
    border-radius: 0.053333rem;

    background-color: #ffffff;
    .play-btn-icon {
      margin-left: 0.4rem;
      margin-top: 0.213333rem;
      width: 0.32rem;
      height: 0.426666rem;
      background: url("../../static/img/palyindex.png") no-repeat;
      background-size: cover;
    }
    .play-btn-icon.ing {
      margin: 0 auto;
      margin-top: 0;
      width: 100%;
      height: 100%;
      background: url("../../static/img/playing.gif") no-repeat;
      background-size: cover;
    }
  }
}
</style>

<script>
/* eslint-disable */
export default {
  name: 'programItem',
  props: {
    data: {
      type: Object,
      default: () => { return {} }
    }
  },
  mounted () {

  },
  beforeCreate() {
    
  },
  computed:{
    playerId(){
      let item = this.$player.getCurrentItem();
      return item ? item.id : 0
    }
  },
  created() { 
    
  },
  updated() {
    
  },
  data () { 
    return {}
  },
  methods: {    
    onPlay () {
        console.log("player", this.$player)
        if(this.$player.isPlayIng()){
          this.$player.pause()
        }else{
          this.$player.show();
          this.$player.playItemById(this.data.id)
        }
    },
    childrenshow() {
      this.$player.playItemById(this.data.id,false)
      this.$router.push({path:'/playhome'})
    },
  }
}
</script>
