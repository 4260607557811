<template>
  <div class="index">
    <div class="head" :class="{active:showAbs}">
      <img class="logo" src="../../static/img/logo.png" alt="凹凸宇宙"/>
      <div class="name">
        <h1 class="atyz">凹凸宇宙</h1>
        <p>为一切欢欣的灵魂</p>
      </div>
      <div class="download" @click="ondownload">下载 <span>APP</span></div>
    </div>
    <ul class="fm-li">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <li v-for="(vo, index) in datas" :key="index">
          <programItem :data="vo"></programItem>
        </li>
      </van-list>
    </ul>
  </div>
</template>

<script>
import programItem from "@/components/programItem";
export default {
  components: {
    programItem
  },
  data() {
    return {
      res:{},
      datas: [],
      loading: false,
      finished: false,
      pageNum: 0,
      showAbs: true,
    };
  },
  methods: {
    ondownload(){
      // alert(this.res.data.download)
      window.location.href=this.res.data.download;
    },
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        this.pageNum++;
        this.getData();
      }, 500);
    },
    getData() {
      // console.log(this.$store.state.player.res)
      if(this.$store.state.player.res){
        let res=this.$store.state.player.res;
        this.res=res;
        this.datas = this.datas.concat(res.data.items);
        this.$player.setList(this.datas, false);
        // 加载状态结束
        this.loading = false;
        this.finished = true;
        return;
      }
      this.$store
        .dispatch("orderMusic", {
          play_type: "order",
          page: this.pageNum
        })
        .then(res => {
          console.log(res);
          this.res=res;
          this.datas = this.datas.concat(res.data.items);
          this.$player.setList(this.datas, false);
          // 加载状态结束
          this.loading = false;
          this.finished = true;
          // if (res.code <= this.pageNum) {
          //   this.finished = true;
          // } else {
          //   this.finished = false;
          // }
        })
        .catch(e => {
          console.log(e);
        });
    },
    handleScroll(){
      // 页面滚动距顶部距离
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var scroll = scrollTop - this.i;
      this.i = scrollTop;
      if(scrollTop<60){
        this.showAbs=true;
        return;
      }
      if(scroll<-10){
        console.log('up')
        this.showAbs=true;
      }else if(scroll>10){
        console.log('down')
        this.showAbs=false;
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/css/page/index";
</style>
